<template>
  <div class="footer-cta">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="footer-content mx-auto">
        <!-- SECTION TITLE  -->
        <div
          class="intro-text brand-dark-blue text-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          What are you waiting for?
        </div>

        <!-- TITLE TEXT  -->
        <div
          class="title-text brand-dark-blue font-weight-700 text-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="90"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Start preparing for the coming JAMB, WAEC and POST UTME examination on
          class54
        </div>

        <!-- DROPDOWN BUTTON  -->
        <div
          class="dropdown-btn position-relative brand-white pointer rounded-50"
          ref="dropdownBtn"
          @mouseenter="show_dropdown = true"
          @mouseleave="show_dropdown = false"
        >
          <div class="text">Download app now</div>
          <div class="icon icon-caret-down"></div>

          <!-- DOWNLOAD DROPDOWN  -->
          <div
            class="
              download-menu
              position-absolute
              brand-dark-green-bg
              rounded-4
              h-auto
              w-100
              smooth-animation
            "
            v-if="show_dropdown"
          >
            <div class="inner-wrapper position-relative w-100 h-auto">
              <div class="connector w-100"></div>
              <!-- ANDROID DOWNLOAD  -->
              <a
                href="https://play.google.com/store/apps/details?id=com.class54.mobile"
                target="_blank"
                class="download-item"
              >
                <div class="text">Android</div>
                <div class="d-icon icon-arrow-right"></div>
              </a>

              <!-- IOS DOWNLOAD  -->
              <a
                href="https://apps.apple.com/us/app/class54/id1622971742"
                target="_blank"
                class="download-item"
              >
                <div class="text">IOS</div>
                <div class="d-icon icon-arrow-right"></div>
              </a>

              <!-- DESKTOP DOWNLOAD  -->
              <router-link to="/jamb-software-download" class="download-item">
                <div class="text">Desktop Software</div>
                <div class="d-icon icon-arrow-right"></div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerCTA",

  data: () => ({
    show_dropdown: false,
  }),

  methods: {
    dropdownBtn() {
      let dropdown = this.$refs.dropdownBtn;
      dropdown.addEventListener(
        "mouseenter",
        () => (this.show_dropdown = true)
      );

      dropdown.addEventListener(
        "mouseleave",
        () => (this.show_dropdown = false)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-cta {
  background: #fff6db;

  .footer-content {
    padding: toRem(64) 0;
    @include flex-column-center;

    @include breakpoint-down(sm) {
      padding: toRem(56) 0;
    }

    .intro-text {
      @include font-height(17, 26);
      margin-bottom: toRem(24);

      @include breakpoint-custom-down(850) {
        @include font-height(15.5, 22);
      }

      @include breakpoint-down(sm) {
        @include font-height(15, 20);
      }

      @include breakpoint-down(xs) {
        @include font-height(14.5, 18);
      }
    }

    .title-text {
      @include font-height(43, 54);
      margin-bottom: toRem(31);
      width: 64%;

      @include breakpoint-down(xl) {
        @include font-height(40, 50);
      }

      @include breakpoint-custom-down(850) {
        @include font-height(32, 40);
      }

      @include breakpoint-down(md) {
        width: 80%;
      }

      @include breakpoint-down(sm) {
        @include font-height(28, 38);
        width: 95%;
      }

      @include breakpoint-down(xs) {
        @include font-height(26, 34);
      }
    }

    .dropdown-btn {
      @include flex-row-center-nowrap;
      @include transition(0.5s);
      padding: toRem(14) toRem(30);
      background: $brand-green;

      .text {
        font-size: toRem(13.5);
        margin-right: toRem(5);
      }

      .icon {
        @include transition(0.5s);
        font-size: toRem(16);
      }

      &:hover {
        background: $brand-dark-green;

        .icon {
          transform: rotate(180deg);
        }
      }

      .download-menu {
        left: 0;
        top: toRem(52);
        padding: toRem(8);
        box-shadow: 0 toRem(18) toRem(80) rgba($brand-black, 0.04),
          0 toRem(11.6667) toRem(46.8519) rgba($brand-black, 0.0303704),
          0 toRem(6.93333) toRem(25.4815) rgba($brand-black, 0.0242963),
          0 toRem(3.6) toRem(13) rgba($brand-black, 0.02),
          0 toRem(1.46667) toRem(6.51852) rgba($brand-black, 0.0157037),
          0 toRem(0.333333) toRem(3.14815) rgba($brand-black, 0.00962963);

        .connector {
          position: absolute;
          border: toRem(1) solid transparent;
          left: 0;
          top: toRem(-20);
          height: toRem(20);
        }

        .download-item {
          @include flex-row-between-nowrap;
          padding: toRem(12) toRem(8);
          @include transition(0.4s);
          border-radius: toRem(4);
          color: $brand-white;

          .text {
            font-size: toRem(13);
          }

          .d-icon {
            @include transition(0.5s);
            font-size: toRem(18);
            display: none;
          }

          &:hover {
            background: #2da771;

            .d-icon {
              display: unset;
            }
          }
        }
      }
    }
  }
}
</style>
